/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, pathname, labela, dataa, labelb, datab }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ogImageURL = `${site.siteMetadata.siteUrl}/opengraph${( image || '/default.jpg' )}`
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${pathname || "/"}`,
        },
        {
          property: `og:image`,
          content: ogImageURL,
        },
        {
          property: `og:image:width`,
          content: `600`,
        },
        {
          property: `og:image:height`,
          content: `315`,
        },
        {
          property: `twitter:image`,
          content: ogImageURL,
        },
        {
          property: `image`,
          content: ogImageURL,
        },
        {
          property: `apple-mobile-web-app-capable`,
          content: `yes`
        },
        {
          property: `apple-mobile-web-app-title`,
          content: `ThirdPlaces`
        },
        {
          property: `apple-mobile-web-app-status-bar-style`,
          content: `default`
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  dataa: PropTypes.string,
  datab: PropTypes.string,
  lista: PropTypes.string,
  listb: PropTypes.string,
}

export default SEO
