import React from "react"
import tw from 'twin.macro'

import { Link } from "gatsby"
import ColorMode from "./colormode"
import GlobalStyles from './../styles/GlobalStyles'

require('typeface-inter')
require('typeface-cardo')

const LayoutComponent = ({ styleContext, children }) => {

  const styles = {
    navigationBar: () => [
      tw`absolute top-0 left-0 z-50 p-4 flex w-full lg:(flex-col justify-between items-center fixed top-0 left-0 bottom-0 w-20 border-0 border-r border-solid border-opacity-100 border-thirdgray-300 dark:(border-thirdgray-dark-200) )`,
    ],
    content: () => [
      tw`lg:(ml-20)`
    ],
    navigationLink: ({ styleContext }) => [
      tw`text-sm no-underline text-thirdgray-900 opacity-50 transition-opacity hover:(opacity-100) dark:(text-thirdgray-dark-900)`,
      (styleContext === 'feature') && tw`text-white lg:(text-thirdgray-900)`
    ],
  }

  const NavigationLink = ({ to, children, partial, styleContext }) => (
    partial ? <PartialNavLink to={to} css={styles.navigationLink({styleContext})}>{children}</PartialNavLink> : <Link to={to} css={styles.navigationLink({styleContext})} activeStyle={{ opacity: 100 }}>{children}</Link>
  )

  const isPartiallyActive = ({
    isPartiallyCurrent
  }) => {
    return isPartiallyCurrent
      ? { style: { opacity: 100}  }
      : null
  }

  const PartialNavLink = props => (
    <Link {...props} getProps={isPartiallyActive} />
  )

  return (
    <React.Fragment>
      <GlobalStyles />
      <div css={styles.navigationBar()}>
        <Link to="/" tw="lg:(m-4)">
          <div tw="flex-1">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" >
              <path
                d="M4 0h8a3 3 0 012.995 2.824L15 3v9.73a3 3 0 01-4.771 2.422l-.15-.117L8 13.302l-2.08 1.733a3 3 0 01-4.915-2.129L1 12.73V3A3 3 0 013.824.005L4 0h8-8zm8 2H4a1 1 0 00-.993.883L3 3v9.73a1 1 0 001.527.85l.113-.082 2.72-2.266a1 1 0 011.175-.077l.105.077 2.72 2.266a1 1 0 001.63-.629l.01-.14V3a1 1 0 00-.883-.993L12 2zM8.44 5.44a1.5 1.5 0 012.12 0c.829.827.657 1.853.015 2.71-.353.471-.904.972-1.675 1.55a1.5 1.5 0 01-1.8 0c-.77-.578-1.322-1.079-1.675-1.55-.642-.857-.814-1.883.014-2.71a1.5 1.5 0 012.554.916L8 6.5l.007-.144c.032-.334.176-.66.432-.917z"
                fillRule="nonzero"
                style={{ fill: 'currentColor' }}
                css={[ (styleContext === 'feature') ? tw`text-white lg:(text-thirdgray-900 dark:(text-thirdgray-dark-900))` : tw`text-thirdgray-900 dark:(text-thirdgray-dark-900)` ]}
              />
            </svg>
          </div>
        </Link>
        <nav tw="flex-1 text-right space-x-3 lg:(absolute transform -rotate-90 left-1/2 top-1/2 -translate-x-1/2 space-x-4)">
          <NavigationLink to="/" styleContext={styleContext}>Home</NavigationLink>
          <NavigationLink to="/collections" partial styleContext={styleContext}>Collections</NavigationLink>
          <NavigationLink to="/places" partial styleContext={styleContext}>Places</NavigationLink>
        </nav>
        <div tw="hidden lg:(block mb-2)">
          <ColorMode/>
        </div>
      </div>
      <div css={styles.content()}>
        {children}
      </div>
    </React.Fragment>
  )
}

export default LayoutComponent
