import React from "react"
import 'twin.macro'

const Section = ({ children, ...props }) => (
  <div tw="mx-auto max-w-screen-2xl p-2 md:(px-2 py-3) lg:(px-16 py-4) 2xl:(px-16 pt-14 pb-20)" {...props}>
    {children}
  </div>
)

export default Section
