import { useEffect, useState } from 'react'

export default function useTheme() {
  const [ theme, setTheme ] = useState( typeof window !== 'undefined' ? window.localStorage.getItem("theme") === "dark" ? 'dark' : 'light' : 'light' )
  const colorTheme = theme === 'light' ? 'dark' : 'light'

  useEffect(() => {
    const root = window.document.documentElement
    root.classList.remove(colorTheme)
    root.classList.add(theme)
    localStorage.setItem('theme', theme)
  }, [theme], colorTheme)
    return [colorTheme, setTheme]
}
