import React from "react"
import 'twin.macro'
import { Link, useStaticQuery, graphql } from "gatsby"
import Section from "./section"

import ColorMode from "./colormode"

function Footer(props) {
  const FooterLink = props => (
    <Link
      tw="text-sm transition block leading-loose text-thirdgray-500 dark:(text-thirdgray-dark-500) no-underline hover:(text-blue-500)"
      {...props}/>
  )

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const currentYear = new Date().getFullYear()

  return (
    <div
      tw="border-solid border-0 px-4 border-t border-thirdgray-200 pt-4 pb-8 md:(py-8) text-thirdgray-500 dark:(border-thirdgray-dark-200 text-thirdgray-dark-500)">
      <Section>
        <div tw="flex justify-between">
          <div
            tw="text-sm leading-loose flex[0 1 55%]">
            <div>
              &copy;{currentYear}&nbsp;
              <Link
                to="/"
                tw="text-thirdgray-500 no-underline hover:(text-blue-500) dark:(text-thirdgray-dark-500)">
                {data.site.siteMetadata.title}
              </Link>
            </div>
            <div tw="pt-4">
              <div tw="flex">
                <a
                  href="https://instagram.com/thirdplac.es/"
                  title="@thirdplac_es on Instagram"
                  target="_blank"
                  rel="noopener noreferrer">
                  <svg
                    width="40"
                    height="40"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ fill: 'currentColor' }}
                    tw="text-thirdgray-400 transition opacity-50 hover:(text-blue-500 opacity-100)">
                    <path
                      d="M20 8.705c3.679 0 4.115.014 5.567.08 1.344.061 2.073.286 2.559.474a4.27 4.27 0 011.584 1.031c.482.482.78.941 1.03 1.584.19.486.414 1.215.475 2.559.066 1.452.08 1.888.08 5.567 0 3.679-.014 4.115-.08 5.567-.061 1.344-.286 2.073-.474 2.559a4.269 4.269 0 01-1.031 1.584c-.482.482-.941.78-1.584 1.03-.486.19-1.215.414-2.559.475-1.452.066-1.888.08-5.567.08-3.68 0-4.115-.014-5.567-.08-1.344-.061-2.073-.286-2.559-.474a4.268 4.268 0 01-1.584-1.031 4.269 4.269 0 01-1.03-1.584c-.19-.486-.414-1.215-.475-2.559-.066-1.452-.08-1.888-.08-5.567 0-3.679.014-4.115.08-5.567.061-1.344.286-2.073.474-2.559a4.269 4.269 0 011.031-1.584 4.27 4.27 0 011.584-1.03c.486-.19 1.215-.414 2.559-.475 1.452-.066 1.888-.08 5.567-.08m0-2.483c-3.742 0-4.211.016-5.68.083-1.467.067-2.469.3-3.345.64a6.754 6.754 0 00-2.44 1.59 6.754 6.754 0 00-1.59 2.44c-.34.876-.573 1.878-.64 3.344-.067 1.47-.083 1.94-.083 5.681 0 3.742.016 4.211.083 5.68.067 1.467.3 2.469.64 3.345a6.754 6.754 0 001.59 2.44 6.754 6.754 0 002.44 1.59c.876.34 1.878.573 3.344.64 1.47.067 1.94.083 5.681.083 3.742 0 4.211-.016 5.68-.083 1.467-.067 2.469-.3 3.345-.64a6.754 6.754 0 002.44-1.59 6.754 6.754 0 001.59-2.44c.34-.876.573-1.878.64-3.344.067-1.47.083-1.94.083-5.681 0-3.742-.016-4.211-.083-5.68-.067-1.467-.3-2.469-.64-3.345a6.754 6.754 0 00-1.59-2.44 6.754 6.754 0 00-2.44-1.59c-.876-.34-1.878-.573-3.344-.64-1.47-.067-1.94-.083-5.681-.083m-.23 6.89a7.119 7.119 0 100 14.236 7.119 7.119 0 000-14.237zm0 11.738a4.62 4.62 0 110-9.241 4.62 4.62 0 010 9.241zm8.037-12.887a1.607 1.607 0 11-3.214 0 1.607 1.607 0 013.214 0"
                      fillRule="evenodd"/>
                  </svg>
                </a>
                <a
                  href="https://facebook.com/thirdplaces"
                  title="@thirdplaces on Facebook"
                  target="_blank"
                  rel="noopener noreferrer">
                  <svg
                    width="40"
                    height="40"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ fill: 'currentColor' }}
                    tw="text-thirdgray-400 transition opacity-50 hover:(text-blue-500 opacity-100)">
                    <path
                      d="M20 6c7.732 0 14 6.268 14 14 0 7.093-5.274 12.953-12.115 13.874v-9.767h3.582l.537-4.159h-4.119v-2.655c0-1.204.335-2.024 2.061-2.024l2.203-.001v-3.72l-.285-.032a32.143 32.143 0 00-2.924-.132c-3.175 0-5.35 1.938-5.35 5.498v3.066H14v4.159h3.59v9.686C11.008 32.651 6 26.91 6 20c0-7.732 6.268-14 14-14z"
                      fillRule="evenodd"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div
            tw="text-left flex[0 1 45%] lg:(text-right)">
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to="/collections">Collections</FooterLink>
            <FooterLink to="/places">Places</FooterLink>
            <FooterLink to="/about">About</FooterLink>
          </div>
        </div>
        <div
          tw="mt-12 text-center lg:(hidden)">
            <ColorMode/>
        </div>
      </Section>
    </div>
  )
}

export default Footer
