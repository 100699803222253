import React from "react"
import useTheme from './../hooks/useTheme'
import tw from 'twin.macro'

const Svg = ({ type, children, colorMode }) => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    css={[
      tw`absolute inset-0 transition-transform transform`,
      (type === 'dark') ? (colorMode === 'light' ? tw`scale-100` : tw`scale-0`) : (colorMode === 'light' ? tw`scale-0` : tw`scale-100`)
    ]}>
    {children}
  </svg>
)

const LightIcon = ({ colorMode }) => (
  <Svg type="light" colorMode={colorMode}>
    <path d="M12 18a1 1 0 011 1v2a1 1 0 01-2 0v-2a1 1 0 011-1zm4.243-1.757a1 1 0 011.414 0l1.414 1.414a1 1 0 01-1.414 1.414l-1.414-1.414a1 1 0 010-1.414zm-9.9 0a1 1 0 011.414 1.414L6.343 19.07a1 1 0 11-1.414-1.414zM12 8a4 4 0 110 8 4 4 0 010-8zm9 3a1 1 0 010 2h-2a1 1 0 010-2zM5 11a1 1 0 010 2H3a1 1 0 010-2zm12.657-6.071a1 1 0 011.414 1.414l-1.414 1.414a1 1 0 11-1.414-1.414zm-12.728 0a1 1 0 011.414 0l1.414 1.414a1 1 0 01-1.414 1.414L4.93 6.343a1 1 0 010-1.414zM12 2a1 1 0 011 1v2a1 1 0 01-2 0V3a1 1 0 011-1z" fill="#7A99B8" fillRule="evenodd" />
  </Svg>
)
 

const DarkIcon = ({ colorMode }) => (
  <Svg type="dark" colorMode={colorMode}>
    <path d="M10.769 3.805A.5.5 0 0111.2 3a9.502 9.502 0 018.774 8.485A9.5 9.5 0 013.11 18.42a.5.5 0 01.425-.812 8.5 8.5 0 009.058-9.369 8.45 8.45 0 00-1.596-4.136l-.23-.298z" fill="#495E77" fillRule="nonzero"/>
  </Svg>
)

const ColourMode = props => {
  const [ colorTheme, setTheme ] = useTheme()
  return (
    <button
      onClick={e => { setTheme(colorTheme) }}
      tw="appearance-none relative pl-6 lg:(bg-transparent cursor-pointer p-0 border-0 w-6 h-6) focus:(outline-none)">
      <LightIcon colorMode={colorTheme}/>
      <DarkIcon colorMode={colorTheme}/>
      <div tw="block text-sm lg:(hidden)">Toggle {colorTheme === 'light' ? 'Dark' : 'Light'} Mode</div>
    </button>
  )
}

export default ColourMode
