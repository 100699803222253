import React from "react"
import { Global, css } from '@emotion/react'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css`
  body {
    ${tw`antialiased bg-thirdgray-50 text-thirdgray-900 dark:(bg-thirdgray-dark-50 text-thirdgray-dark-900)`},
  }
`

const GlobalStyles = () => (
  <React.Fragment>
    <BaseStyles />
    <Global styles={customStyles} />
  </React.Fragment>
)

export default GlobalStyles
